<template>
  <div class="container-grid">
    <div class="headerTitle"><slot name="header-title"/></div>
    <div v-if="arraySlideInfo && arraySlideInfo.length !== 0" class="grid">
      <div class="grid-item" v-for="(item, index) in arraySlideInfo" :key="index">
        <slot name="swiper-card" v-bind:item="item" />
      </div>
    </div>
    <slot name="modals" />
  </div>
</template>

<script>
export default {
  name: 'container-grid',
  props: {
    arraySlideInfo: {
      type: Array,
      default: () => []
    }
  }
}

</script>

<style scoped lang="scss">
.headerTitle:empty {
  display: none;
}
.container-grid {
  justify-content: center;
  margin: 0 auto;
  &.w-983 {
    max-width: 983px;
  }
  &.space-small {
    .grid {
      .grid-item {
        margin: 6px !important;
      }
    }
  }
  &.space-medium {
    .grid {
      .grid-item {
        margin: 12px !important;
      }
    }
  }
}
.grid {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .grid-item {
    margin: 4px 0;
    @media screen and (max-width: 580px){
      &:nth-child(odd) {
        margin-right: 8px;
      }
    }
    @media screen and (min-width: 580px){
      margin: 16px !important;

    }
  }
}
</style>
