
export default {
  data () {
    return {
      authMixinField: 'item.id'
    }
  },
  methods: {
    Favorite (itemId) {
      if (typeof itemId === 'undefined') return
      this.$store.dispatch('TogleFavorite', {
        idProfissional: itemId
      }).then(() => {
        this.favorite = !this.favorite
      })
    }
  }
}
