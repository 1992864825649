<template>
  <div class="modal-details">
    <container-page :style="{'max-height': '100%', overflow: 'auto', 'max-width': '960px'}">
      <button-close class="modal-btn-close" @close="$emit('close')" />
      <div class="container">
      <div class="main">
          <div class="content-personal">
            <div class="status-bar">
              <span>
                <span class="status-personal">{{DataDetalhes.estado}}</span>
                <span class="btn-favorite">
                  <label title="Profissional Favorite">
                      <input @change="Favorite(DataDetalhes.id)" type="checkbox" :checked="favorite" :value="DataDetalhes.numOrdem" />
                      <i class="active fa fa-heart" aria-hidden="true"></i>
                  </label>
                </span>
              </span>
            </div>
            <div class="main-wrapper">
              <user-avatar :avatar="DataDetalhes.avatarUrl" :tipoRise="DataDetalhes.tipoRiseImagem"/>
              <div class="content-profissional">
                <div class="code">{{DataDetalhes.numOrdem}}</div>
                <div  class="sector-wrapper" v-if="DataDetalhes.avaliacaoProfissional">
                  <div class="sector-container" v-for="(item, index) in DataDetalhes.avaliacaoProfissional.sectores" :key="index">
                    <img width="23" :src="item.iconeUrl" alt />
                    <p class="sector-name">{{item.nome}}</p>
                  </div>
                </div>
                <span class="label-text">Função</span>
                <p class="text-medium">{{DataDetalhes.funcao}}</p>
                <span class="label-text">Nacionalidade</span>
                <p class="text-small">{{DataDetalhes.pais}} <span class="bagde gray">{{DataDetalhes.linguaMaterna}}</span></p>
                <span class="label-text">Habilitações Literárias</span>
                <p class="text-small">{{DataDetalhes.habilitacao}}</p>
                <span class="label-text">Localização Actual</span>
                <p class="text-small">{{DataDetalhes.cidade}}</p>
                <span class="label-text">{{DataDetalhes.idiomas && DataDetalhes.idiomas.length === 1 ? 'Idioma': 'Idiomas'}}</span>
                <p>
                  <span
                  class="bagde bagde-idioma"
                  v-for="(item, index) in DataDetalhes.idiomas"
                  :key="index">{{item.idioma}}/{{item.nivel}}</span>
                </p>
              </div>
            </div>
        </div>
        <div class="content-body">
          <p class="description" v-if="DataDetalhes.avaliacaoProfissional">
            {{DataDetalhes.avaliacaoProfissional.comentario}}
          </p>
          <group-card-datalist :DataDetalhes="DataDetalhes" />
        </div>
      </div>
      <div class="aside"
      :class="{'disponibilidade-only': DataDetalhes.idEstado !== 5}">
        <button
        v-if="displayBtn && DataDetalhes.idEstado === 5"
        class="see-more"
        @click="ShowModal">Saiba mais...</button>
        <ul class="stars" v-if="DataDetalhes.idTipoRiser > 2">
          <li>Formação</li>
          <li>
            <p
            v-for="(item, index) in 5"
            class="fa fa-star"
            :class="{'star-checked': (index < DataDetalhes.avaliacaoProfissional.rateAproveitamento)}" :key="index"></p>
          </li>
          <li>Comunicação</li>
          <li>
            <span
            v-for="(item, index) in 5"
            class="fa fa-star"
            :class="{'star-checked': (index < DataDetalhes.avaliacaoProfissional.rateComunicacao)}" :key="index"></span>
          </li>
          <li>Vídeo CV</li>
          <li>
            <span
            v-for="(item, index) in 5"
            class="fa fa-star"
            :class="{'star-checked': (index < DataDetalhes.avaliacaoProfissional.ratePitch)}" :key="index"></span>
          </li>
        </ul>
        <ul class="list-item" v-if="DataDetalhes.idTipoRiser > 2">
          <li>Pontos Fortes</li>
          <li>
            <span class="bagde gray bagde-mb" v-for="(ponto, index) in DataDetalhes.avaliacaoProfissional.avaliacaoPontosFortes" :key="index">{{ponto.descricao}}</span>
          </li>
          <li>Pontos Melhoria</li>
          <li>
            <span class="bagde gray bagde-mb" v-for="(ponto, index) in DataDetalhes.avaliacaoProfissional.avaliacaoPontosPorMelhorar" :key="index">{{ponto.descricao}}</span>
          </li>
        </ul>
        <div class="footer">
          <ul class="disponibilidade list-item">
            <li>Cidade Disponibilidade</li>
            <li>
              <span class="bagde gray mr mt mb" v-for="(ponto, index) in DataDetalhes.cidadesDisponivel" :key="index">{{ponto.nome}}</span>
            </li>
          </ul>
          <figure>
              <img class="rise-top-button" width="80" v-if="DataDetalhes.tipoRiseImagem" :src="DataDetalhes.tipoRiseImagem" alt="Tipo Riser" srcset />
              <caption class="text-rise-top">{{DataDetalhes.tipoRiser}}</caption>
          </figure>
        </div>
      </div>
    </div>
    </container-page>
    <confirm-modal
      v-if="confirmModal"
      @close="confirmModal = false"
      @send="SendPedido()"
    />
  </div>
</template>

<script>
import { getModalDetails } from '@/api/services/profissional'
import { isAuthenticatedParceiro } from '@/utils/auth'
import ConfirmModal from '@/components shared/Modal/ConfirmModal'
import ContainerPage from '@/components shared/container-card'
import GroupCardDatalist from '@/components shared/Modal/group-card-datalist'
import UserAvatar from '@/components shared/user-avatar'
import AuthMixin from '@/mixins/auth-mixin'
export default {
  name: 'ModaDetails',
  mixins: [AuthMixin],
  mounted () {
    if (isAuthenticatedParceiro()) {
      this.displayBtn = true
    }
    getModalDetails({ Id_profissional: this.idFromList }).then(resp => {
      this.DataDetalhes = resp.data.object
      this.favorite = resp.data.object.favorito
    })
  },
  props: {
    idFromList: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      test: false,
      favorite: false,
      DataDetalhes: {},
      edContainer: true,
      epContainer: true,
      foContainer: true,
      confirmModal: false,
      stateSend: 'Saber mais',
      displayBtn: false,
      getId: null,
      edText: true,
      epText: true,
      foText: true,
      rotateToggleEp: true,
      rotateToggleEd: true,
      rotateToggleFo: true
    }
  },
  components: {
    'confirm-modal': ConfirmModal,
    'container-page': ContainerPage,
    'group-card-datalist': GroupCardDatalist,
    'user-avatar': UserAvatar,
    'button-close': require('@/components shared/button-close-modal').default
  },
  methods: {
    ShowModal () {
      this.confirmModal = true
    },
    SendPedido () {
      this.$store
        .dispatch('SendPedido', {
          idProfissional: this.idFromList
        })
        .then(sucess => {
          // this.stateSend = 'Enviado'
          if (sucess === false) {
            // this.stateSend = 'Não Enviado'
          }
        })
        .catch(() => {
          this.error = true
        })
    },
    toggleFo () {
      this.foContainer = !this.foContainer
      this.foText = !this.foText
      this.rotateToggleFo = !this.rotateToggleFo
      if (this.foContainer === false) {
        this.epContainer = true
        this.edContainer = true
        this.epText = true
        this.edText = true
        this.rotateToggleEp = true
        this.rotateToggleEd = true
      }
    },
    toggleEd () {
      this.edContainer = !this.edContainer
      this.edText = !this.edText
      this.rotateToggleEd = !this.rotateToggleEd
      if (this.edContainer === false) {
        this.epContainer = true
        this.foContainer = true
        this.epText = true
        this.foText = true
        this.rotateToggleEp = true
        this.rotateToggleFo = true
      }
    },
    toggleEp () {
      this.epContainer = !this.epContainer
      this.epText = !this.epText
      this.rotateToggleEp = !this.rotateToggleEp
      if (this.epContainer === false) {
        this.edContainer = true
        this.foContainer = true
        this.edText = true
        this.foText = true
        this.rotateToggleEd = true
        this.rotateToggleFo = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-btn-close {
  position: absolute;
  z-index: 777;
  right: 10px;
  transform: translateY(10px);
  @media screen and (min-width: 960px) {
    right: initial;
    transform: translateX(960px);
  }
  @media screen and (min-width: 1100px) {
    right: initial;
    transform: translateX(990px);
  }
}
.btn-favorite {
  input {
    display: none;
  }
  label {
    cursor: pointer;
    i {
      color: #ccc;
      font-size: 2em;
    }
    input:checked + i {
      color: #f00;
    }
  }
}
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 960px;
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
  .main {
    flex: 0.70;
    display: flex;
    max-height: 100%;
    flex-direction: column;
    .content-personal {
      background-color: #FCB72D;
      padding-bottom: 15px;
      padding-right: 15px;
      @media screen and (min-width: 760px){
        border-top-left-radius: 20px;
      }
      .status-bar {
        text-align: right;
        font-size: 12px;
        color: #fff;
        &> span {
          display: inline-flex;
          align-items: center;
          margin-top: 16px;
          span {
            margin: 0 6px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        .status-personal {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          padding: 6px 6px;
          border-radius: 30px;
          background-color: #27CDA3;
          font-size: 0.9em;
        }
      }
      .main-wrapper {
        display: flex;
        align-items: flex-start;
        .content-profissional {
          color: #fff;
          .code {
            color: #503660;
            font-size: 20px;
            margin-top: -10px;
            margin-bottom: 10px;
          }
          .sector-wrapper {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            &:first-child {
              margin-top: 0;
            }
            &:last-child {
              margin-bottom: 0;
            }
            .sector-container {
              display: flex;
              align-items: center;
              background-color: #fff;
              border-radius: 10px;
              max-width: 150px;
              padding: 4px 8px;
              margin: 4px 3px;
              box-shadow: 0 2px 9px 3px rgba(29, 29, 29, 0.116);
              img {
                margin-right: 8px;
              }
              .sector-name {
                font-size: 10px;
                color: #26A0A5;
                font-weight: 800;
              }
            }
          }
          .text-medium {
            font-size: 16px;
          }
          .text-medium, .text-small {
            margin: 2px 0;
            span {
              margin-left: 10px;
            }
          }
          .text-small {
            font-size: 12px;
          }
          .label-text {
            font-size: 11px;
            color: #28083b;
            font-weight: 600;
          }
        }
      }
    }
    .content-body {
      color: #4B335A;
      padding: 30px 24px;
      background-color: #fff;
      @media screen and (min-width: 760px) {
       border-bottom-left-radius: 20px;
      }
      flex: 1;
      .description {
        margin-bottom: 10px;
        font-size: 12px;
      }
    }
  }
  .bagde {
    color: #fff;
    font-size: 10px;
    padding: 3px 6px;
    border-radius: 30px;
    display: inline-block;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &:first-child:last-child {
      margin: 3px 0;
    }
    &:empty {
      display: none;
    }
    &.gray {
      background-color: #EFEFEF;
      color: #505050;
    }
    &.bagde-mr {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    &.bagde-mb {
      margin-bottom: 5px;
    }
    .mt {
      margin-top: 4px;
      &:last-child {
        margin-top: 0;
      }
    }
  }
  .bagde-idioma {
    background-color: #26A0A5;
    margin: 2px 10px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
      margin-left: 0;
    }
  }
  .aside {
    flex: 0.33;
    background-color: #26A0A5;
    @media screen and (min-width: 768px) {
      border-radius: 0 20px 20px 0;
    }
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.disponibilidade-only {
      justify-content: flex-end !important;
    }
    .see-more {
      background-color: #4B335A;
      width: 78%;
      margin: 30px auto;
      display: block;
      color: #fff;
      padding: 12px 0;
      font-size: 1.3em;
      border-radius: 2px;
      border-style: none;
      outline-style: none;
      box-shadow: 0 0 4px #444;
      cursor: pointer;
      &:active {
        opacity: 0.7;
      }
    }
    ul, .footer {
      list-style: none;
      padding-left: 0;
      width: 86%;
      margin: 0 auto;
    }
    .list-item {
      margin-bottom: 16px;
      li {
        &:nth-child(odd) {
          margin-bottom: 4px;
          font-size: 12px;
        }
        &:nth-child(even) {
          margin-bottom: 18px;
        }
      }
    }
    .disponibilidade {
      li:first-child {
        font-size: 14px !important;
      }
      .mr {
        margin-right: 5px;
        &:last-child {
          margin-right: 0;
        }
      }
      .mb {
        margin-bottom: 5px;
      }
    }
    .stars {
      text-align: right;
      margin-top: 40px;
      margin-bottom: 16px;
      li {
        margin: 4px 0;
      }
      li:nth-child(even) {
        display: flex;
      flex-direction: row-reverse;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
      .fa-star {
        color: #FFFFFF;
      }
      .star-checked {
        color:#FDB00B

      }
      }
      li:nth-child(odd) {
        color: #4B335A;
        font-size: 12px;
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      .disponibilidade {
        width: 63%;
      }
      figure {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 30%;
        text-align: center;
        margin-top: 30px;
        caption {
          font-size: 12px;
          color: #595959;
        }
      }
    }
  }
}
#details{
  margin-top: 10px!important;
}
.text-area {
  color: #9d9d9d;
  font-size: 16px;
  margin-top: 10px;
  text-align: start;
  font-weight: lighter;
}
.info-detais-mobile{
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 100%;
  max-width: initial;
  position:relative;
  width: 100%;
  @media(min-width: 768px){
    display: none;
  }
  .countre-city {
    color: #9d9d9d;
    font-size: 16px;
    text-align: left;
    padding: 5px 10px 5px 0;
  }
}
.details-container {
  display: flex;
  margin-top: 5px;
  height: auto;
  align-items: center;
  @media(max-width:768px){
    margin-top: 10px;
    padding-top:5px ;
  }
  .countre-city {
    color: #9d9d9d;
    font-size: 16px;
    padding-right: 10px;
  }

}
.id-and-favorite-container{
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  .details-container-id {
    display: flex;
    // margin-top: 5px;
    .id-text {
      color: #9d9d9d;
      font-size: 18px;
    }
    .id {
      color: #4b335a;
      padding: 0 10px;
      font-size: 18px;
    }
  }
  .favorite-container{
    position: relative!important;
    left: unset!important;
    top: unset!important;
  }
}
.rise-top-mobile {
  position: absolute;
  left: 150px;
  width: 60px;
  top: 155px;
  @media(max-width:768px){
    left: 180px;
    top: 60px;
  }
}
.circle-image::after {
  content: "";
  display: block;
  margin: 41px 0 0 10px;
  border-radius: 50%;
  width: 124px;
  height: 124px;
  background: #fdb00b 0% 0% no-repeat padding-box;
  opacity: 0.72;
  filter: blur(15px);
  @media (max-width: 768px) {
    margin: 21px 0 0 -15px !important;
    width: 96px !important;
    height: 96px !important;
    margin: 18px !important;
  }
}
.circle-image {
  width: 148px;
  height: 148px;
  background: #fdb00b;
  border-radius: 50%;
  margin-bottom: 13px;
  @media (max-width: 768px) {
    margin-bottom: 26px !important;
    width: 109px !important;
    height: 109px !important;
  }
}
.wrapper-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  @media (max-width: 768px) {
    width: 110px !important;
    height: 110px !important;
    margin-bottom: 25px !important;
    img {
      width: 110px !important;
      height: 110px !important;
    }
  }
  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    -webkit-box-shadow: 0px 1px 0px 1px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 0px 1px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 0px 1px rgba(0,0,0,0.15);
  }
}
.photo-wrapper-mobile{
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  @media(min-width: 768px){
    display: none;
  }
}
 .category-container-mobile{
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
  height: 100%;
  @media(min-width: 768px){
    display: none;
  }
}
.category-wrapper2{
  @media(max-width:768px){
    padding:5px 10px!important;
    width: auto!important;
    margin-bottom: 10px!important;
  }
}
.category-wrapper2,
.category-wrapper {
  display: flex;
  width: auto;
  height: 30px!important;
  align-items: center;
  margin: 2px 10px 2px 0;
  justify-content: flex-start;
  padding: 0 10px;
  background-color: #fff;
  border-radius: 36px;
  -webkit-box-shadow: 0px 0px 19px -9px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0px 0px 19px -9px rgba(0, 0, 0, 0.7);
  box-shadow: 0px 0px 19px -9px rgba(0, 0, 0, 0.7);
  @media(max-width:768px){
    padding: 15px 0;
    width: 110px;
    .sector-text-name{
      text-align: left;
    }
  }
  p {
    color: #26a0a5;
    padding-left: 5px;
    font-size: 8px;
  }
  img {
    width: 18px;
    height: auto;
  }
}
.modal-details {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4b335a;
  z-index: 99999999;
  backdrop-filter: blur(11px);
  -webkit-backdrop-filter: blur(11px);
  transition: opacity 0.3s ease;
}
.favorite-container{
  width: 20px;
  height: auto;
  top: 22px;
  position: absolute;
  left: 240px;
  @media(max-width:768px){
    top: 22px;
  }
  img{
    width: 100%;
    cursor: pointer;
  }
}
.classification-wrapper{
  @media(max-width:768px){
    display: none;
  }
}
.classification-mobile{
   @media(min-width:768px){
    display: none;
  }
}
.circle-image::after {
  content: "";
  display: block;
  margin: 41px 0 0 10px;
  border-radius: 50%;
  width: 124px;
  height: 124px;
  background: #fdb00b 0% 0% no-repeat padding-box;
  opacity: 0.72;
  filter: blur(15px);
  @media (max-width: 768px) {
    margin: 21px 0 0 -15px !important;
    width: 96px !important;
    height: 96px !important;
    margin: 18px !important;
  }
}
.circle-image {
  width: 148px;
  height: 148px;
  background: #fdb00b;
  border-radius: 50%;
  margin-bottom: 13px;
  @media (max-width: 768px) {
    margin-bottom: 26px !important;
    width: 109px !important;
    height: 109px !important;
  }
}
.wrapper-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  @media (max-width: 768px) {
    width: 110px !important;
    height: 110px !important;
    margin-bottom: 25px !important;
    img {
      width: 110px !important;
      height: 110px !important;
    }
  }
  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    -webkit-box-shadow: 0px 1px 0px 1px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 0px 1px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 0px 1px rgba(0,0,0,0.15);
  }
}
.btn-contrate{
  width: 286px!important;
  @media(max-width: 768px){
    display: none;
  }
}
.btn-contrate,
.btn-contrate-mobile {
  background-color: #26a0a5;
  color: #fff;
  border-radius: 5px;
  height: 52px;
  width: 254px;
  border: none;
  // padding: 30px 0;
  font-size: 20px;
  cursor: pointer;
  // margin-bottom: 20px;
  margin: 10px auto 20px;
  @media(max-width: 768px){
    height: 42px;
  }
}
.btn-contrate-mobile{
  position: relative;
  z-index: 885;
  left: 0;
  right: 0;
  bottom: 0;
  @media(min-width: 768px){
    display: none;
  }
}
.modal-container {
  width: 70%;
  max-width: 1063px;
  height: 80%;
  display: flex;
  border-radius: 5px;
  box-shadow: 6px 10px 12px #00000029;
  @media(max-width: 768px) {
    width: 90%;
    height: 80%;
    flex-direction: column !important;
  }
  .sepator {
    width: 10px;
    height: 100%;
    background-color: #26a0a5;
    @media(max-width: 768px){
      width: 100%;
      height: 10px;
    }
  }
  .container {
    padding: 4%;
    flex: 1;
    background-color: #fff;
    display: flex;
    height: 100%;
    overflow-y: auto;
    flex-direction: column;
    position: relative;
    @media(max-width: 768px){
      height: auto;
    }

    .arrowToggleContainer {
      margin-top: 12px;
      margin-left: 5px;
      .arrowToggle {
        width: 20px;
        cursor: pointer;
      }
    }
    .rise-top-container {
      // position: absolute;
      margin-right: 50px;
      bottom: 0;
      margin-top: 25px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      @media(max-width: 768px){
        display: none;
      }
      .text-rise-top {
        text-align: center;
        font-size: 10px;
        margin-top: 5px;
        color: #595959;
      }
      .rise-top-button {
        width: 80px;
      }
    }
    .container-top {
      // position: relative;
      width: 100%;
      display: flex;
      height: 100%;
      @media(max-width:768px){
        flex-direction: column;
      }
      .section-left {
        display: flex;
        flex: 1;
        flex-direction: column;
        @media(max-width:768px){
          height: auto!important;
          width: 100%;
          // padding-bottom: 15px;
        }
        .profile-info {
          display: flex;
          width: 100%;
          @media(max-width:768px){
            flex-direction: column;
            align-items: center;
            height: auto!important;
            display: none;
          }
          .rise-top {
            position: absolute;
            left: 150px;
            width: 60px;
            top: 155px;
            @media(max-width:768px){
              left: 180px;
              top: 70px;
            }
          }
          .user-proto {
            width: 150px;
            height: 146px;
            background-color: #fdb00b;
            border-radius: 50%;
          }
          .info-container {
            display: flex;
            flex-direction: column;
            margin-left: 50px;
            position: relative;
            justify-content: center;
            @media(max-width:768px){
              margin-left: 0;
              height: 100%;
              width: 100%;
              // margin-bottom: 500px;
            }
            .category-container{
              display: flex;
              flex-wrap: wrap;
              margin: 10px 0;
              height: auto;
            }
            .category-wrapper2{
              @media(max-width:768px){
                padding:5px 10px!important;
                width: auto!important;
                margin-bottom: 10px!important;
              }
            }
            .category-wrapper2,
            .category-wrapper {
              display: flex;
              width: auto;
              height: 30px!important;
              align-items: center;
              margin: 2px 10px 2px 0;
              justify-content: flex-start;
              padding: 0 10px;
              background-color: #fff;
              border-radius: 36px;
              -webkit-box-shadow: 0px 0px 19px -9px rgba(0, 0, 0, 0.7);
              -moz-box-shadow: 0px 0px 19px -9px rgba(0, 0, 0, 0.7);
              box-shadow: 0px 0px 19px -9px rgba(0, 0, 0, 0.7);
              @media(max-width:768px){
                padding: 15px 0;
                width: 110px;
                .sector-text-name{
                  text-align: left;
                }
              }
              p {
                color: #26a0a5;
                padding-left: 5px;
                font-size: 8px;
              }
              img {
                width: 18px;
                height: auto;
              }
            }
            .state-label {
              top: 25px;
              position: absolute;
              left: 282px;
              border-radius: 10px;
              padding: 2px 2px;
              width: 70px;
              @media(max-width:768px){
                display: none;
              }
              .state-text {
                color: #fff;
                text-transform: uppercase;
                font-size: 8px;
              }
            }
            .text-area {
              color: #9d9d9d;
              font-size: 20px;
              margin-top: 10px;
              text-align: start;
              font-weight: lighter;
              max-width: 352px;
              @media(max-width:768px){
                text-align: start;
                font-size: 16px;
              }
            }
            .details-container-id{
              margin-top: 20px!important;
            }
            .details-container-id,
            .details-container {
              display: flex;
              margin-top: 5px;
              @media(max-width:768px){
                margin-top: 10px;
              }
              .id-text {
                color: #9d9d9d;
                font-size: 18px;
              }
              .id {
                color: #4b335a;
                padding: 0 10px;
                font-size: 18px;
              }
              .countre-city {
                color: #9d9d9d;
                font-size: 16px;
                padding-right: 10px;
              }
              .lang-container {
                background-color: #efefef;
                border-radius: 34px;
                width: 70px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                .lang-text {
                  color: #505050;
                  font-size: 10px;
                }
              }
            }
          }
        }
        .text-wrapper {
          padding: 50px 0;
          max-width: 450px;
          @media(max-width:768px){
            display: none;
            height: 100%;
            padding: 20px 0;
          }
          p {
            text-align: left;
            color: #4b335a;
            font-size: 14px;
          }
        }
      }
      .section-right {
        @media(max-width:768px){
          // position: relative;
          // bottom: -470px;
          // left: 0;
          // right: 0;
          height: 100%;
          display: none;
          // flex-direction: column-reverse;
        }
      }
    }
    .container-button {
      width: 100%;
      display: flex;
      @media(max-width: 768px){
        flex-direction: column;
        height: 100%;
        position: relative;
      }
      .content-right {
        min-width: 288px;
        @media(min-width:768px){
          margin-top: -60px;
        }
        .formation-container{
          div{
            margin: 5px!important;
          }
          @media(min-width: 786px){
            max-width: 288px;
            flex-wrap: wrap;
            // margin-top: -60px;
          }
        }
        // .title-content-right2 {
        //   color: #26a0a5 !important;
        // }
        .title-content-right2,
        .title-content-right {
          text-align: left;
          font-size: 13px;
          font-weight: 600;
          color: #4b335a;
          margin-left: 5px;
        }
      }
      .content-left {
        flex: 1;
        padding-right: 100px;
        // max-width: 500px;
        @media(max-width:768px){
          // margin-top: 250px;
          padding-right: 0;
         padding-top: 20px;
        }
      }
      .content-right,
      .content-left,
      .content {
        .title-content-left {
          text-align: left;
          font-size: 12px;
          font-weight: bold;
          color: #4b335a;
          margin-left: 5px;
        }
        .subTitle-wrapper-skills {
          min-width: 50px !important;
          width: 80px !important;
        }
        .subTitle-wrapper-skills,
        .subTitle-wrapper {
          background-color: #efefef;
          padding: 4px 0;
          min-width: 50px;
          width: auto;
          border-radius: 12px;
          margin: 10px 5px;
          padding: 3px 10px;
          @media(max-width:768px){
            min-width: unset;
          }
          .subTitle-text-active {
            font-weight: bolder;
          }
          .subTitle-text-active,
          .subTitle-text {
            font-size: 10px;
            color: #9d9d9d;
            padding: 2px 0;
            text-align: left;
          }
        }
        .rotate-toggle-ed,
        .rotate-toggle-fo,
        .rotate-toggle-ep {
          transform: rotate(180deg);
          transition: transform 0.2s;
        }
        .ep-text,
        .ed-text,
        .fo-text {
          display: none;
          transition: display 0.4s;
        }
        .ep-container,
        .ed-container,
        .fo-container {
          height: auto !important;
        }
        .education-container,
        .experience-container,
        .formation-container {
          display: flex;
        }
      }
    }
  }
}
</style>
