 <template>
  <div class="swiper-card">
    <slot>
      <div class="slide-label-content">
        <div :class="[item.estado.replace(/\s/g,'-' )]" class="slide-label" :style="{'background':item.estadoCor}">
          <p>{{item.estado}}</p>
        </div>
        <div class="favorite-container">
          <div  v-show="verifyAuth === true" class="star-rating">
            <input @change="Favorite(item.idProfissional || item.id)" :id="`profissional-${timestamps}`" type="checkbox" :checked="item.favorito" :value="item.numOrdem" />
            <label :for="`profissional-${timestamps}`" title="Profissional Favorite">
            <i class="active fa fa-heart" aria-hidden="true"></i>
            </label>
          </div>
        </div>
      </div>
      <div class="slide-content">
        <user-avatar class="user-medium" :avatar="item.avatarUrl" :tipoRise="item.tipoRiseImagem"/>
        <div class="h1-content">
          <h1>{{item.funcao}}</h1>
        </div>
      </div>
      <button class="btn" @click="$emit('handler-profissional', item.idProfissional || item.id)">Ver perfil</button>
    </slot>
  </div>
</template>

<script>
import { isAuthenticatedParceiro } from '@/utils/auth'
import AuthMixin from '@/mixins/auth-mixin'
export default {
  name: 'TopTenCard',
  mixins: [AuthMixin],
  props: {
    nameId: {
      type: String,
      default: 'id'
    },
    arraySlideInfo: {
      type: Array,
      default: () => []
    },
    item: Object
  },
  components: {
    'user-avatar': require('@/components shared/user-avatar').default
  },
  mounted () {
    if (isAuthenticatedParceiro()) {
      this.verifyAuth = true
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper
    }
  },
  data () {
    return {
      authMixinField: 'item.id',
      timestamps: Date.now(),
      showModal: false,
      verifyAuth: false,
      favorite: this.item.favorito || false
    }
  }
}

</script>

<style scoped lang="scss">
  .swiper-card {
    position: relative;
    background: #fff;
    border-radius: 14px;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 10px;
    padding-bottom: 20px;
    width: 304px;
    height: 341px;
    margin: 0;
    box-shadow: 0px 0px 30px -6px rgba(0,0,0,0.20);
    transition: box-shadow 200ms ease-out 0s, transform 200ms 0s ease-out;
    transform: scale(1);
    @media screen and (min-width: 975px) and (max-width: 1440px) {
      width: 170px;
      height: 207px;
      padding: 5px;
    }
    @media screen and (min-width: 1440px) and (max-width: 1640px) {
      width: 263px;
      height: 225px;
      padding: 8px;
    }
    &:hover {
      transition: box-shadow 300ms ease-out 0s;
      box-shadow: 0px 0px 14px -2px rgba(0,0,0,0.50);
      animation: animation-bounce 0.4s 100ms 1 ease-out forwards;
    }
    .slide-label-content{
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }
    @keyframes animation-bounce {
      50% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(0.94);
      }
    }
  }
  .favorite-container {
    width: 20px;
    height: auto;
    img {
      width: 100%;
      cursor: pointer;
    }
  }
 .slide-label {
    display: flex;
    align-items: center;
    border-radius: 36px;
    padding: 9px 10px;
    height: 23px;
    p {
      color: #fff;
      text-transform: uppercase;
      font-size: 12px;
    }
    @media screen and (min-width: 975px) and (max-width: 1440px) {
      border-radius: 26px;
      padding: 4px 5px;
      height: 16px;
      p {
        font-size: 7px;
      }
    }
    @media screen and (min-width: 1440px) and (max-width: 1640px) {
      p {
        font-size: 10px;
      }
    }
  }
  .slide-content {
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .h1-content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 5px;
      padding: 5px 0;
      h1 {
        font-family: "NexaBold", sans-serif;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        font-size: 20px;
        @media screen and (min-width: 975px) and (max-width: 1440px) {
          font-size: 12px;
        }
        @media screen and (min-width: 1440px) and (max-width: 1640px) {
          font-size: 14px;
        }
      }
    }
  }
  .btn {
    border: none;
    border-radius: 36px;
    font-size: 18px;
    margin: 0;
    width: 120px;
    padding: 8px 0;
    background: #26A0A5;
    box-shadow: 0 4px 5px 1px #26a1a5c2;
    color: #fff;
    cursor: pointer;
    font-family: "NexaLight",sans-serif;
    @media screen and (min-width: 975px) and (max-width: 1600px) {
      width: 90px;
      padding: 4px 0;
      font-size: 14px;
    }
  }
</style>
