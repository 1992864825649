<template>
<div>
  <card-datalist
    :isShow="educacao"
    title="Educação"
    fieldTitle="areaEducacao"
    :items="DataDetalhes.educacoes"
    @toggle-panel="()=> setPanel('educacao')"
    >
      <template #default="{item: edu}">
        <p class="subtitle-text">{{edu.designacao}}</p>
        <p class="subtitle-text">{{edu.instituicaoEnsino}}</p>
        <p class="subtitle-text">{{edu.grauAcademico}}</p>
      </template>
    </card-datalist>
    <card-datalist
    :isShow="formacao"
    v-if="DataDetalhes.formacoes && DataDetalhes.formacoes.length"
    title="Formação"
    fieldTitle="areaFormacao"
    :items="DataDetalhes.formacoes"
    @toggle-panel="() => setPanel('formacao')"
    >
      <template #default="{item: formacao}">
        <p class="subtitle-text">{{formacao.curso}} <span v-if="formacao.duracao">({{formacao.duracao}})</span></p>
        <p class="subtitle-text">{{formacao.instituicao}}</p>
      </template>
    </card-datalist>
    <card-datalist
    :isShow="experiencia"
    title="Experiência Profissional"
    fieldTitle="funcao"
    :items="DataDetalhes.experienciasProfissional"
    @toggle-panel="() => setPanel('experiencia')"
    >
      <template #default="{item: experienciaProfissional}">
        <p class="subtitle-text">{{experienciaProfissional.empresa}} ({{experienciaProfissional.experiencia}})</p>
        <p class="subtitle-text">{{experienciaProfissional.sector}}</p>
        <p class="subtitle-text">{{experienciaProfissional.descricao}}</p>
      </template>
    </card-datalist>
</div>
</template>

<script>
export default {
  name: 'group-card-datalist',
  props: {
    DataDetalhes: Object
  },
  components: {
    'card-datalist': require('@/components shared/Modal/card-datalist').default
  },
  data () {
    return {
      educacao: true,
      formacao: false,
      experiencia: false
    }
  },
  methods: {
    setPanel (prop) {
      if (prop !== 'educacao') this.educacao = false
      if (prop !== 'experiencia') this.experiencia = false
      if (prop !== 'formacao') this.formacao = false
      this[prop] = !this[prop]
    }
  }
}
</script>
<style lang="scss" scoped>
.subtitle-text {
  font-size: 12px;
  margin: 6px 0;
  &:empty {
    display: none;
  }
}

</style>
