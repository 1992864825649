<template>
<div v-if="items && items.length">
  <p class="title">{{title}}</p>
  <div class="container-datalist" :class="{active: isShow}">
    <div class="wrapper">
      <div class="datalist" :class="{active: isShow}" v-for="(item, id) in items" :key="id">
        <div class="summary">
        <p class="summary-title">{{item[fieldTitle]}}</p>
        </div>
        <div class="content" v-if="isShow">
          <slot :item="item" />
        </div>
      </div>
    </div>
    <button class="btn" @click="$emit('toggle-panel')">
      <img
        :class="{'rotate-close':isShow}"
        class="arrowToggle"
        src="@/assets/img/arrowToggle.svg"
        alt
      />
    </button>
  </div>
</div>
</template>
<script>
export default {
  name: 'card-datalist',
  props: {
    title: String,
    items: Array,
    fieldTitle: String,
    isShow: Boolean
  }
}
</script>
<style lang="scss" scoped>
.title {
  font-weight: 700;
  margin: 30px 0 10px 0;
}
.container-datalist {
  display: flex;
  align-items: flex-start;
}
.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @media screen and (min-width: 760px){
    flex-direction: row;
  }
  &.active {
    align-items: flex-start;
  }
  .datalist {
    color: #fff;
    background-color: #4B335A;
    border-radius: 30px;
    margin: 10px 0;
    &:first-child {
      margin-top: 0;
    }
    @media screen and (min-width: 760px){
      margin: 0 10px;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &.active {
      flex: 1;
      max-width: 240px;
      padding: 8px;
      border-radius: 8px;
      .summary .summary-title {
        padding-left: 0;
      }
    }
    .summary {
      display: flex;
      align-items: center;
      .summary-title {
        padding: 4px 8px;
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
}
.btn {
  margin-left: 6px;
  border-radius: 50px;
  background-color: transparent;
  border-style: none;
  outline-style: none;
  cursor: pointer;
  img {
  transition: transform .3s ease-out 0s;
  position: relative;
  }
  img.rotate-close {
    transform: rotate(180deg);
  }
}
</style>
