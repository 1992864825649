
import { url } from '@/api/base-url'

export function getProfissionais () {
  return url.get('PerfilProfissionais')
}

export function getFunctions () {
  return url.get('funcoes')
}

export function getProfissional () {
  return url.get('Profissionais?page=1')
}

export function getArea () {
  return url.get('funcoes')
}

export function getModalDetails (
  filters = {
    Id_profissional: '8aa437d2-68d2-4069-b21c-a9126cf5f824'
  }) {
  return url.get(`Profissionais/${filters.Id_profissional}`)
}
